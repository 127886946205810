import React, { Component } from 'react'

export default class Video extends Component {
  render() {
    return (
        <div>
            <h1 style={{color: "rgba(0,0,0,.5)", fontWeight: "500", fontStyle: "italic", margin: "10px 0 20px 0"}}>interview with wolfears!</h1>
            <iframe className='heroVideo' style={{width: "100%", padding: "40px 10% 40px 10%", background: "white url(https://gdurl.com/5PMF) repeat fixed top"}} width="560" height="315" src="https://www.youtube.com/embed/Yi1npbeL_MM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h1 style={{color: "rgba(0,0,0,.5)", fontWeight: "500", fontStyle: "italic", margin: "10px 0 20px 0"}}>interview with ashley ninelives!</h1>
            <iframe className='heroVideo' style={{width: "100%", padding: "40px 10% 40px 10%", background: "white url(https://gdurl.com/5PMF) repeat fixed top"}} width="560" height="315" src="https://www.youtube.com/embed/1C1j_-2tBfk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h1 style={{color: "rgba(0,0,0,.5)", fontWeight: "500", fontStyle: "italic", margin: "10px 0 20px 0"}}>interview with hyenablood!</h1>
            <iframe className='heroVideo' style={{width: "100%", padding: "40px 10% 40px 10%", background: "white url(https://gdurl.com/5PMF) repeat fixed top"}} width="560" height="315" src="https://www.youtube.com/embed/al2b4PC8-Ac" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h1 style={{color: "rgba(0,0,0,.5)", fontWeight: "500", fontStyle: "italic", margin: "10px 0 20px 0"}}>interview with scuttlefuzz!</h1>
            <iframe className='heroVideo' style={{width: "100%", padding: "40px 10% 40px 10%", background: "white url(https://gdurl.com/5PMF) repeat fixed top"}} width="560" height="315" src="https://www.youtube.com/embed/rVbeu8_pgbA?si=EMYsdjuPWLeZjyb7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    )
  }
}

